import React from 'react';
import Layout from '@components/structure/Layout.jsx';
import Seo from '@components/utility/Seo.jsx';
import SitemapContent from '@components/structure/SitemapContent';

const Index = () => {

  return (
    <Layout>
      <Seo title='Official XYREM® | 404 - Not Found' canonical="/404" />
      <section className='container-md section-container pageLinks section section--first'>
        <h1 className="blueHeading text-center">Page not found</h1>
        <SitemapContent />
      </section>
    </Layout>
  )
};

export default Index;
